<template>
  <!-- Hidden on Mobile -->
  <div class="row mobile-menu mt-4">
    <div class="col-6">
      <button class="btn-sm-grey">
        <router-link :to="{ name: 'UserDashboard' }">Dashboard</router-link>
      </button>
    </div>
    <div class="col-6 cursor-pointer btn-right">
      <button class="btn-sm-grey" @click="logout">
        <i class="bi bi-power"></i> Log out
      </button>
    </div>
  </div>
  <div class="row user-menu user-menu__desktop">
    <div class="user-menu__item mb-4">
      <el-avatar :size="40" :src="image" @click="showModal = true" />
      <p>Hello {{ username }}</p>
    </div>
    <div class="user-menu__item">
      <router-link to="/u/dashboard">Dashboard</router-link>
    </div>
    <div class="user-menu__item">
      <router-link to="/u/profile">My profile</router-link>
    </div>
    <div class="user-menu__item">
      <router-link to="/u/password">My password</router-link>
    </div>
    <div class="user-menu__item">
      <router-link to="/u/activity">My activity</router-link>
    </div>
    <div class="user_menu__logout cursor-pointer mt-4">
      <button class="btn-sm-grey" @click="logout">
        <i class="bi bi-power"></i> Log out
      </button>
    </div>
  </div>
  <el-dialog v-model="showModal">
    <template #header>
      <h4>Submit news</h4>
    </template>
    <app-form
      :form="form"
      :model="model"
      :rules="rules"
      button="Submit"
      button-side="right"
      @submitted="handleImgChange"
    />
  </el-dialog>
</template>

<script>
import Database from '@/utils/services/Database';
import { form, model, rules } from '@/utils/config/avatar';

export default {
  name: 'UserMenu',
  data() {
    return {
      username: '',
      image: '',
      showModal: false,
      form,
      model,
      rules,
    };
  },
  async created() {
    await this.getUser();
  },
  methods: {
    async getUser() {
      const user = await this.$store.dispatch('getUser');
      this.username = user.username;
      this.image = user.image;
    },
    async logout() {
      const status = await this.$store.dispatch('logout');

      if (status === 204) this.$router.push({ name: 'Home' });
    },
    async handleImgChange(data) {
      const { img_url } = await Database.upload('users', data.image);
      this.image = img_url;

      const status = await Database.updateUser({ image: img_url });

      if (status !== 200)
        return this.$message({
          type: 'error',
          message: 'Error while updating your user image. Please try again.',
          offset: 200,
        });

      this.$message({
        type: 'success',
        message: 'Image updated successfully',
        offset: 200,
      });

      await this.getUser();
      this.showModal = false;
    },
  },
};
</script>

<style lang="scss">
.mobile-menu {
  display: none;
}
.user-menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: $sp-2x;
  padding-bottom: 20rem;
  border-right: 1px solid $light-grey;
  .btn-sm-grey {
    transform: translateX(-1rem);
  }
  &__item {
    border-bottom: 1px solid $light-grey;
    padding: calc($sp/2) 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-weight: bold;
    .el-avatar {
      cursor: pointer;
      margin-right: calc($sp/2);
    }
    i {
      margin-right: calc($sp/2);
      font-size: 25px;
    }
    p {
      margin-bottom: 0;
    }
    &.text-end {
      justify-content: flex-end;
    }
    a {
      transition: $trs-default;
      &:hover,
      &.router-link-active {
        color: $danger !important;
      }
    }
  }
}

@include bp-down(lg) {
  .user-menu {
    display: none;
  }
  .mobile-menu {
    display: flex;
    justify-content: center;
    align-items: center;
    a {
      color: $primary !important;
      &:hover{
        color: $danger !important;
      }
    }
  }
}
</style>
